/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  margin: 0px;
  overflow-x: hidden;
  padding: 0px;
  width: 100vw;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 12px;
  line-height: normal;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #333;
  background-color: #fff;
  font-display: swap;
}

/* reset button styles to make it easier to use buttons off the bat */
button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
}
